.pop-up-container {
  height: 100%;
  width: auto;
  /* display: table; */
  /* margin: auto; */
  position: static;
}

@media (max-width: 400px) {
  .pop-up-container {
    margin: 2% 0;
  }
}

.pop-up-container-vertical {
  height: 100%;
  vertical-align: middle;
  /* display: table-cell; */
}

.pop-up-wrapper {
  display: block;
  width: auto;
  position: relative;
  background-color: white;
  display: block;
  padding: 50px;
}

@media (max-width: 600px) {
  .pop-up-wrapper {
    padding: 20px;
  }
}

.go-back {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #222;
  width: 0;
  height: 0;
  border-top: 60px solid #cae00d;
  border-right: 60px solid transparent;
}

.go-back i {
  font-size: 20px;
  position: relative;
  top: -52px;
  left: 10px;
}

@media (max-width: 600px) {
  .go-back {
    display: none;
  }
}

.product-details {
  /* max-width: 920px; */
}
.product-info {
  padding: 0 20px;
}

.product-left {
  display: inline-block;
  padding-right: 4%;
  vertical-align: top;
  width: 40%;
}

.product-right {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  clear: both;
}

.product-bottom {
  border-top: 1px solid #ccc;
  position: relative;
  padding-top: 20px;
}

@media (max-width: 650px) {
  .product-left,
  .product-right,
  .product-bottom {
    width: 100%;
  }
  .product-left {
    padding-right: 0;
  }
  .product-info {
    display: inline-block;
    width: 60%;
    vertical-align: top;
  }
  .product-image {
    display: flex;
    width: 39%;
    vertical-align: top;
    padding: 10px 10px 0 10px;
    justify-content: center;
  }
}

@media (max-width: 512px) {
  .product-info,
  .product-image {
    width: 100%;
    padding: 0;
  }
}

.product-manufacturer {
  color: #222;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1;
  margin: -2px;
}

.product-title {
  font-size: 1.2rem;
  color: #888;
  margin: 5px 0;
}

.product-price {
  color: #222;
  font-size: 12px;
  letter-spacing: 1px;
}
.product-price span {
  color: #24695c;
  font-size: 20px;
  letter-spacing: 1px;
}
.product-image {
  display: flex;
  vertical-align: top;
  padding: 10px 10px 0 10px;
  justify-content: center;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  height: 295px;
}

.product-description {
  line-height: 1.5;
  padding: 10px 0;
  float: left;
}

.product-available {
  margin-top: 25px;
  clear: both;
}

.product-rating {
  margin-top: 25px;
}

i.fa-star {
  color: #aaa;
  display: inline-block;
}
i.fa-star.rating {
  color: rgb(232, 217, 31);
}
.product-rating-details {
  display: inline-block;
  padding-left: 10px;
}
@media (max-width: 515px) {
  .product-rating-details {
    padding-left: 0;
  }
}
.product-extended {
  color: #235ba8;
  padding-left: 5px;
}
.product-quantity {
  margin-top: 25px;
  margin-bottom: 25px;
}

.product-checkout {
  position: absolute;
  left: 0;
  font-size: 12px;
  text-transform: uppercase;
}
.product-checkout-actions {
  position: absolute;
  right: 0;
}
.product-checkout-total,
.product-checkout-total-amount {
  font-size: 20px;
  color: #c17a41;
}
.product-checkout-total * {
  display: inline-block;
}

/* 6. Components - buttons, menus, images, etc. */
.product-quantity-label {
  text-transform: uppercase;
}
.product-quantity * {
  display: inline-block;
}

#product-quantity-input {
  background-color: #eee;
  border: none;
  width: 2.5em;
  text-align: center;
}
.product-quantity-subtract,
.product-quantity-add {
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.product-quantity-subtract {
  margin-right: 20px;
}

.toast {
  position: fixed;
  top: -50px;
  left: calc(50vw - 50px);
  z-index: 25;
  padding: 5px 10px;
  border-radius: 15px;
}

.toast-success {
  background-color: green;
  color: white;
  font-size: 9pt;
}

.toast-transition {
  top: calc(50px);
  transition: top 1s;
}

/* 7. Trumps - text helpers, often !important */

.hidden {
  display: none;
}

.pickup-details i {
  color: #58cab9;
  font-size: 1.4rem;
  margin-right: 0.4rem;
}

.list-with-icons {
  list-style: none;
}

.list-with-icons li:before {
  content: "\2713";
  color: #24695c;
  padding-right: 5px;
  font-weight: bold;
}
.list-with-icons-error {
  list-style: none;
}

.list-with-icons-error li:before {
  content: "\2713";
  color: #dc3545;
  padding-right: 5px;
  font-weight: bold;
}

.defects-list {
  list-style: none;
  counter-reset: custom-counter;
  padding-left: 3.5rem;
}
.defects-list li {
  counter-increment: custom-counter;
  margin-bottom: 1rem;
  position: relative;
}

.defects-list li::before {
  content: counter(custom-counter);
  position: absolute;
  left: -2.5rem;
  top: 0;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  border-radius: 50%;
  background: #58cab9;
  text-align: center;
  margin-right: 0.5rem;
}
.defects-list li.deduction::before {
  background: #d9534f;
}


/* contact details */
.contact-details {
  list-style: none;
  animation: list-items-animation 0.5s ease 1 both;
}
.contact-details .colorCat {
  font-size: 1rem;
}

.contact-details:nth-child(2) {
  animation-delay: 0.2s;
}
.contact-details:nth-child(3) {
  animation-delay: 0.4s;
}

.contact-details:not(:last-child) {
  margin-bottom: 0.8rem;
}

.contact-details a {
  text-decoration: none;
  text-transform: capitalize;
  color: black;
}

.contact-details i {
  margin-right: 0.6rem;
}

@keyframes list-items-animation {
  from {
    opacity: 0;
    transform: translateX(-5rem);
    /* margin-left: -3rem; */
  }

  to {
    opacity: 1;
    transform: translateX(0);
    /* margin-left: 0; */
  }
}