.app-page-title {
  /* padding: 30px; */
  /* margin: -30px -30px 0px; */
  position: relative;
}
.app-page-title + .body-tabs-layout {
  margin-top: -30px !important;
}
.app-page-title .page-title-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.app-page-title .page-title-heading,
.app-page-title .page-title-subheading {
  margin: 0;
  padding: 0;
}
.app-page-title .page-title-heading {
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  align-content: center;
  align-items: center;
}
.app-page-title .page-title-subheading {
  padding: 3px 0 0;
  font-size: 0.88rem;
  opacity: 0.6;
}
.app-page-title .page-title-subheading .breadcrumb {
  padding: 0;
  margin: 3px 0 0;
  background: transparent;
}
.app-page-title .page-title-actions {
  margin-left: auto;
}
.app-page-title .page-title-actions .breadcrumb {
  margin: 0;
  padding: 0;
  background: transparent;
}
.app-page-title .page-title-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 20px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-radius: 0.25rem;
  width: 50px;
  height: 50px;
}
.app-page-title .page-title-icon i {
  margin: auto;
}
.app-page-title .page-title-icon.rounded-circle {
  margin: 0 20px 0 0;
}
.app-page-title + .RRT__container {
  margin-top: -23.07692px;
}
.app-page-title.app-page-title-simple {
  margin: 0;
  background: none !important;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.app-page-title .page-title-actions {
  margin: 15px auto 0;
}
.app-page-title .page-title-actions .breadcrumb-item,
.app-page-title .page-title-actions .breadcrumb,
.app-page-title .page-title-subheading .breadcrumb-item,
.app-page-title .page-title-subheading .breadcrumb {
  display: inline-block;
}

/* tablets */
@media (max-width: 991.98px) {
  .app-page-title .page-title-heading,
  .app-page-title .page-title-wrapper {
    margin: 0 auto;
  }
  .app-page-title {
    /* padding: 30px; */
 
    position: relative;
  }
}

/* mobile */
@media (max-width: 600px) {
  .app-page-title .page-title-heading,
  .app-page-title .page-title-wrapper {
    margin: 0 auto;
    display: block;
  }

  .app-page-title .page-title-icon {
    display: none;
  }
  .app-page-title {
  
    position: relative;
    /* text-align: center; */
  }
}
