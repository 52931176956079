.card-button,
.card-button {
  background-color: rgba(36, 105, 92, 0.1) !important;
  border: none !important;
  color: #24695c;
  transition: 0.5s;
  padding: 6px 16px;
  margin-right: 0;
  cursor: pointer;
}
.card-button svg,
.card-button svg {
  width: 16px;
  margin: 0 2px 0 0;
}

.button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgba(36, 105, 92, 0.1) !important;
  border: none !important;
  color: #24695c;
  transition: 0.5s;
  padding: 6px 16px;
  cursor: pointer;
}
.button svg {
  width: 16px;
}
