body {
  height: 100%;
}

@import url("./assets/css/bootstrap_limitless.min.css");
/* @import url("./assets/css/components.min.css"); */

/* icons  */
@import url("./assets/fonts/icomoon/styles.min.css");

/* fonts */

.page-heading {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.page-heading h3 {
  font-size: 18px;
  font-weight: bold;
}

.add-brand-wrapper {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.384);
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  max-height: calc(100vh - 76px);
}

.add-brand-wrapper .add-brand {
  width: 60%;
  margin: 60px auto;
  max-width: 60%;
  height: fit-content;
}

.del-btn {
  color: #9c27b0;
  cursor: pointer;
  font-size: 0.8rem !important;
  padding: 0.5rem;
  background: none;
  border: none;
  outline: none;
}
.del-btn:hover {
  color: #4b0058;
  font-size: 0.8rem;

  padding: 0.5rem;
}

.edit-btn {
  color: #24695c;
  cursor: pointer;
  font-size: 0.8rem !important;
  padding: 0.5rem;
  background: none;
  border: none;
  outline: none;
}
.edit-btn:hover {
  color: #0d3f36;
  font-size: 0.8rem;

  padding: 0.5rem;
}

.delete-wrapper {
  position: fixed !important;
  inset: 0;
  left: 290px;
  top: 76px;
  width: calc(100% - 290px) !important;
  background-color: rgba(0, 0, 0, 0.384);
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  max-height: calc(100vh - 76px);
  z-index: 1;
}

.delete-wrapper .delete {
  position: relative;
  width: 60%;
  margin: 60px auto;
  max-width: 60%;
  height: fit-content;
}

.main-nav .nav-toggle {
  display: none;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
  top: 76px !important;
}

.card-footer {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.form-deduction {
  background: #e9e0ea;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  margin-bottom: 50px;
}

.add-btn {
  color: #24695c;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 16px;
  font-weight: bold;
}

.add-btn:hover {
  color: #0d3f36;
}

.page-title {
  color: #000;
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 1.4rem;
}
.page-title span {
  color: #58cab9;
  font-weight: bold;
}
a .card:hover {
  background-color: #f9fafa;
  box-shadow: 1px 1px 4px #73958f;
}
.show-hide {
  z-index: 3 !important;
}
.show-hide span.show:before {
  content: "" !important;
}
.card .id {
  font-size: 10px;
}
@media screen and (max-width: 1000px) {
  .main-nav {
    left: -290px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
    top: 53px !important;
  }

  .main-nav .nav-toggle {
    position: fixed;
    right: 10px;
    bottom: 10px;
    background: #24695c;
    color: white;
    z-index: 99;
    padding: 0 10px;
    font-size: 30px;
    border-radius: 8px;
    display: block;
    cursor: pointer;
  }

  .main-nav.show-mobile {
    left: 0;
  }
}

@media screen and (max-width: 991px) {
  .page-wrapper .page-main-header .main-header-right .nav-right > ul {
    top: unset !important;
    position: relative !important;
    z-index: 1 !important;
    background-color: #fff !important;
    transition: all linear 0.3s !important;
    border-top: unset !important;
    box-shadow: unset !important;
    width: unset !important;
    left: unset !important;
    padding: unset !important;
    transform: unset !important;
    opacity: 1 !important;
    visibility: visible !important;
    height: unset !important;
  }
  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 575px) {
  .main-nav {
    top: 50px !important;
  }
}

/* button loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn .lds-ring {
  height: 19px !important;
  width: 19px !important;
}
.btn .lds-ring div {
  width: 15px;
  height: 15px;
  border-width: 2px;
  margin: 7px 10px 7px 10px;
}

/* Search bar */
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  box-shadow: none;
  border: 1px solid #0d3f36 !important;
}

.ant-btn-icon-only > .anticon {
  color: #0d3f36;
}

/* ant breadcrumbs */
.ant-breadcrumb > span:last-child a {
  color: rgb(88, 202, 185) !important;
}

/* antd tabs */

.ant-tabs-ink-bar {
  background: rgb(88, 202, 185) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:hover {
  color: rgb(88, 202, 185) !important;
}

/* antd button primary */
.ant-btn-primary {
  border-color: rgb(88, 202, 185) !important;
  background: rgb(88, 202, 185) !important;
}

/* antd steps */
.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: rgb(88, 202, 185) !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgb(88, 202, 185) !important;
}

/* MUI DATATABLE */
@media (max-width: 959.95px) {
  /* .MUIDataTableBodyCell-stackedCommon-76 {
    width: calc(100%) !important;
  } */
}

/* DROPDOWN SELECT */
.ant-select-disabled .ant-select-arrow {
  display: none !important;
}
.ant-select-arrow {
  color: #000 !important;
}

/* ANT TAG */
.ant-tag {
  font-size: 10px !important;
  line-height: 15px !important;
}

.ModalRoot_modalRoot__fMd81 {
  background-color: rgba(1,1,1,.5);
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 900;
}