.search-wrapper {
  width: 200px;
}


@media screen and (max-width: 575px) {
  .search-wrapper {
    width: 100%;
  }
}
