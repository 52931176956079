*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

:root {
  --color-border: #eaeced;
  --border-radius: 0.5rem;
}
.searchbar {
  z-index: 100;
  font-size: 2vmin;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); */
  border-radius: var(--border-radius);
  margin: 0 10em;
}
 @media(max-width: 600px) {
     .searchbar {

 display:none
}
 }
 
  
 

.search {
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
}
.search:focus-within {
  /* box-shadow: 0 0 3px #92a4de; */
  border: 1px solid rgb(88, 202, 185);
}
.search > svg {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  stroke: rgb(88, 202, 185);
}

.search-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  /* Because we're using focus-within above. Probably needs @supports */
}
.search-input:focus {
  outline: none;
}

.results {
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 50vh;
  width: 100%;
  z-index: -1;
  font-size: 0.6em;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  margin: 0 0;
  padding: 0;
  list-style-type: none;
}
.results:before {
  content: "";
  position: absolute;
  top: calc(var(--border-radius) * -1);
  left: 0;
  width: 100%;
  background-color: #f7f9fb;
  height: calc((var(--height, 0) * 1px) + var(--border-radius));
  transition: height 0.5s;
  border-radius: inherit;
}

.result-item {
  margin: 0.25rem 0.5em;
  padding: 0.5em 0.6em;
  list-style-type: none;
  border-radius: 0.5rem;
  -webkit-animation: pop-in 0.3s backwards;
  animation: pop-in 0.3s backwards;
  -webkit-animation-delay: calc(var(--i) * 0.08s);
  animation-delay: calc(var(--i) * 0.08s);
  background-color: transparent;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #333;
}
.result-item:before {
  content: "dog";
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: bold;
  background: #6d7386;
  color: white;
  border-radius: 0.25em;
  margin-right: 0.5rem;
  display: inline-block;
  padding: 0.25em;
}
.result-item:hover {
  background-color: #edeff2;
}
@-webkit-keyframes pop-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes pop-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
}
