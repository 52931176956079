.content {
  position: relative;
}
.title {
  font-size: 1.1rem;
}
.close-button {
  position: absolute;
  right: -30px;
  top: -15px;
  border: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background: white;
  transition: all 0.2s ease-in-out;
}
.close-button:hover {
  transform: scale(1.1);
}
.close-button span {
  color: red;
}
.modal__body {
  max-height: calc(100vh - 150px);
  /* max-height: 100vh; */
  overflow-y: auto;
}
